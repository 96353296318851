import * as React from "react"
// import Slider from "react-slick";
import Layout from "../components/layout"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import Title from "../components/title"

const Contact = ({ data }) => {
  const pageData = {
    titleClass: "press",
    title: "自動車専用船の非自走貨物用ラッシング資材管理をIoTで可視化する実証実験を実施",
    discription: "",
  }
  const seodata = {
      title: "自動車専用船の非自走貨物用ラッシング資材管理をIoTで可視化する実証実験を実施",
      seo: {
        description: "非自走貨物を輸送する際に使用するラッシング(貨物固縛)資材の適正管理を目的として、IoTデバイスを活用し、ラッシング資材収納用のラッシングボックスを可視化する実証実験を行いました。",
      },
      image: {
        url: data.datoCmsImage.topimage[21].url,
      },
      slug: "press",
  }
  // const newsData = data.allDatoCmsNews.nodes

  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <Title titlestyle={pageData} />
      <div className="press_container">
        <p className="date">2021年11月25日<br/>アヴィエラン株式会社</p>
        <p>
        川崎汽船株式会社(以下、「川崎汽船」)とアヴィエラン株式会社(以下、「アヴィエラン」)は、自動車船事業において非自走貨物を輸送する際に使用するラッシング(貨物固縛)資材の適正管理を目的として、IoTデバイスを活用し、ラッシング資材収納用のラッシングボックスを可視化する実証実験を行いました。
        </p>
        <p>
        川崎汽船は、自動車船事業でロールトレーラーを利用した非自走貨物輸送サービスを展開しています。ロールトレーラーへ貨物を固縛する際にはロールトレーラー1本に付き数十本のラッシング資材を利用します。このラッシング資材は管理本数が多く、揚げ地での紛失が課題となっています。対策として本船には資材を回収するラッシングボックスを付帯させていますが、ボックス自体の管理も課題となっていました。そこで今回、課題解決の最初のステップとしてラッシングボックスの位置情報の見える化に取り組みました。
        </p>
        <p>
        アヴィエランは、ラッシング資材の製造販売、ソフトウェア開発、ECソリューションを行っていますが、今回そのノウハウを活かして物流IoTソリューションを開発、その実証実験を今年9月から2か月間、日豪航路に投入している当社の自動車専用船で行いました。まず、ラッシングボックスに位置計測機能付きIoTデバイスを取り付けて、輸送中のボックスの位置を目標とした精度でシステム上に可視化できたことを確認、また、輸送中の振動や熱など、デバイスの耐久性に問題がないことも確認しました。これにより予定とは異なる動きを検知、また、ラッシングボックスの地域ごとの位置情報を可視化することが可能となりました。
        </p>
        <p>
        今回のラッシングボックスの位置情報・状況の可視化の実証実験の結果を踏まえ、ボックス、ラッシング資材、ロールトレーラーの管理の連携、管理の最適化へと取り組みを一歩先に進め、資材管理の高度化と業務効率の改善に繋げる計画です。
        </p>

        <p><img src="https://www.slingbelt.com/template/default/img/top/20211117press.jpg" alt="実証検証を行ったシステムのイメージ図" /></p>

      </div>
    </Layout>
  )
}

export default Contact

export const query = graphql`

  query   {
    allDatoCmsNews{
      nodes {
        title
        data(formatString: "YYYY/MM/DD")
        meta {
          createdAt(formatString: "YYYY/MM/DD")
          updatedAt(formatString: "YYYY/MM/DD")
        }
      }
    }
    datoCmsImage {
      topimage {
        url
        fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`
